import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import ReactMarkdown from 'react-markdown';
import FloatingWindow from './floating_window';
import { Link, redirect } from 'react-router-dom';
import { Mic, MicOff } from 'lucide-react';
import PDFFloatingWindow from './pdf_floating_window';

const supabase = createClient(
    "https://jtmcxhywzhzmamsxkuco.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp0bWN4aHl3emh6bWFtc3hrdWNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTcyNjc1MjEsImV4cCI6MjAzMjg0MzUyMX0.Jz8JLULy5HHlGeM3zG53YS9kwHIsrGODDvtmIkfbqwQ"
);

const MemoizedFloatingWindow = React.memo(FloatingWindow);
const MemoizedPDFFloatingWindow = React.memo(PDFFloatingWindow);
const Chat = ({ user }) => {
    const [question, setQuestion] = useState('');
    const [messages, setMessages] = useState([]);
    const [hindiMode, setHindiMode] = useState(false);
    const [textsFileNames, setTextsFileNames] = useState([]);
    const [isListening, setIsListening] = useState(false);
    const [pdfPath, setPdfPath] = useState("");
    const [pdfHighlights, setPdfHighlights] = useState([]);
    const [pdf_floating_window, setPdfFloatingWindow] = useState(false);
    const [pageNumber, setPageNumber] = useState(null);

    const recognitionRef = useRef(null);
    const timeoutRef = useRef(null);
    const formRef = useRef(null);

    const currentTime = new Date();
    const [floatingWindowContent, setFloatingWindowContent] = useState({ url: null, title: '' });

    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Fetch public.profiles from Supabase
        async function fetchUserProfile() {
            console.log(user); 
            if (!user) return;

            let { data, error } = await supabase
                .from('profiles')
                .select('hindi_mode')
                .eq('id', user.id)
                .single();

            if (error) {
                console.error('Error fetching user profile:', error.message);
                return;
            }

            // Set hindiMode based on fetched data
            if (data) {
                // console.log(data); 
                setHindiMode(data.hindi_mode);
            }
        }

        fetchUserProfile();
    }, [user]);

    const getGreeting = () => {
        if (!user) {
            console.error('You must be signed in to ask a question.');
            return;
        }

        const currentHour = currentTime.getHours();
        if (currentHour < 12) {
            return 'Good morning ' + user.email.split('@')[0] + '!';
        } else if (currentHour < 18) {
            return 'Good afternoon ' + user.email.split('@')[0] + '!';
        } else {
            return 'Good evening ' + user.email.split('@')[0] + '!';
        }
    };

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            recognitionRef.current = new SpeechRecognition();
            recognitionRef.current.continuous = false;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                setQuestion(transcript);

                // Clear the previous timeout and set a new one
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    stopListeningAndSubmit();
                }, 3000); 
            };

            recognitionRef.current.onerror = (event) => {
                console.error('Speech recognition error', event.error);
                setIsListening(false);
            };

            recognitionRef.current.onend = () => {
                setIsListening(false);
                if (question.trim()) {
                    formRef.current.requestSubmit();
                }
            };
        } else {
            console.log('Speech recognition not supported');
        }

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const toggleListening = () => {
        if (isListening) {
            stopListeningAndSubmit();
        } else {
            startListening();
        }
    };

    const startListening = () => {
        setQuestion('');
        recognitionRef.current.start();
        setIsListening(true);
    };

    const stopListeningAndSubmit = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
        setIsListening(false);

        if (question.trim()) {
            console.log(question);
            formRef.current.requestSubmit();
        }
    };

    const askQuestion = async (e) => {
        e && e.preventDefault(); // Check if e exists before calling preventDefault
        if (!user) {
            console.error('You must be signed in to ask a question.');
            return;
        }

        const { data: { session } } = await supabase.auth.getSession();
        const token = session.access_token;

        setMessages([...messages, { type: 'user', content: question }, { type: 'response', content: '' }]);
        setQuestion('');
        const mode = hindiMode ? "hindi" : "english";
        const res = await fetch('https://mosaic.parakeetlabs.com/ask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ question, mode: mode })
        });
    
        if (res.ok) {
            const reader = res.body.getReader();
            const decoder = new TextDecoder();
            let result = '';
            let textsFileNames = null;
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                // Decode the streamed value
                const chunk = decoder.decode(value, { stream: true });
                result += chunk;

    
                // Check for the end of response and the start of texts_file_names
                if (result.includes("END_OF_RESPONSE")) {
                    // Handle texts_file_names separately
                    const textsFileNamesStart = result.indexOf("TEXTS_FILE_NAMES_START:");
                    const textsFileNamesEnd = result.indexOf(":TEXTS_FILE_NAMES_END");
    
                    if (textsFileNamesStart !== -1 && textsFileNamesEnd !== -1) {
                        const textsFileNamesString = result.substring(
                            textsFileNamesStart + "TEXTS_FILE_NAMES_START:".length,
                            textsFileNamesEnd
                        );
    
                        try {
                            textsFileNames = JSON.parse(textsFileNamesString);
                        } catch (error) {
                            console.error("Error parsing texts_file_names:", error);
                        }
    
                        // Remove texts_file_names from the main result
                        // console.log(result.substring(textsFileNamesStart, textsFileNamesEnd + ":TEXTS_FILE_NAMES_END".length))
                        result = result.replace(result.substring(textsFileNamesStart , textsFileNamesEnd + ":TEXTS_FILE_NAMES_END".length), "");
                    }
    
                }
    
                // Update the message content in the state with the current result
                setMessages(prev => {
                    const updatedMessages = [...prev];
                    updatedMessages[updatedMessages.length - 1].content = result;
                    // remove END_OF_RESPONSE from the end of the content
                    if (updatedMessages[updatedMessages.length - 1].content.includes("END_OF_RESPONSE")) {
                        updatedMessages[updatedMessages.length - 1].content = updatedMessages[updatedMessages.length - 1].content.replace("END_OF_RESPONSE", "");
                    }
                    return updatedMessages;
                });
            }
    
            // Handle the parsed texts_file_names
            if (textsFileNames) {
                setTextsFileNames(textsFileNames);
            }
        } else {
            console.error('Error:', res.statusText);
        }    
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleCloseFloatingWindow = useCallback(() => {
        setFloatingWindowContent({ url: null, title: '' });
    }, []);

    const handleClosePdfFloatingWindow = useCallback(() => {
        setPdfFloatingWindow(false);
    }, []);

    const customLinkRenderer = ({ href, children }) => {
        if (href.includes(".pdf")) {
            // textFileNames is the array of json 
            // console.log(textsFileNames);
            //textFileNames:[
            //     {
            //         "file_name": "2021-2022_Q4.pdf",
            //         "text": "is the lock screen of your phone. So, what Glance has done is to bring in rel evant content that people can consume even while their phones are in locked mode. So really converting the last screen of the phone into a very usable and valuable property for end users. We have invested 200 billion US dollars in Glance, because we believ e in the potential of this platform, not just for Indian customers but for global customers. And obviously there is a close integration that we are doing with them for all of the devices that we ourselves are"
            //     }
            // ]
            const only_thatfilenamehighlights = textsFileNames.filter(text => text.file_name === href);
            const pdfHighlights = only_thatfilenamehighlights.map(text => text.text);
            const page_number = only_thatfilenamehighlights.map(text => text.page_number);
            // console.log(pdfHighlights);
            const pdfPath = "https://mosaic.blr1.cdn.digitaloceanspaces.com/" + href;
            return (
                <a href={pdfPath} onClick={(e) => {
                    e.preventDefault();
                    setPdfHighlights(pdfHighlights);
                    setPdfPath(pdfPath);
                    setPdfFloatingWindow(true);
                    setPageNumber(page_number);
                }} className='text-blue-500 hover:underline'>
                    {children}
                </a>
            )
        }
        const isYouTube = href.includes('youtube.com') || href.includes('youtu.be');
        console.log(href, isYouTube); 
        return (
            <a
                href={href}
                onClick={(e) => {
                    e.preventDefault();
                    const title = children;

                    if (isYouTube) {
                        setFloatingWindowContent({ url: href, title: title });
                    } else {
                        setFloatingWindowContent({ url: href, title: title });
                    }
                }}
                className='text-blue-500 hover:underline'
            >
                {children}
            </a>
        );
    };

    const signOut = async (event) => {
        event.preventDefault();
        await supabase.auth.signOut();
        redirect("/login");
    };

    return (
        <div className='flex flex-col h-screen'>
            <nav className="w-full border-b h-16 flex justify-center bg-[#FAF3EA] shadow-sm">
            <a className="flex justify-start items-center m-4 text-black text-xl font-bold"  href='/'> MOSAIC </a>
                <div className="w-full max-w-4xl flex justify-end p-3">
                    {user ? (
                        <div className="flex items-center gap-4">
                            Hey, {user.email}!
                            <form onSubmit={signOut}>
                                <button className="py-2 px-4 rounded-md no-underline bg-btn-background hover:bg-btn-background-hover">
                                    Logout
                                </button>
                            </form>
                        </div>
                    ) : (
                        <Link
                            to="/login"
                            className="py-2 px-3 flex rounded-md no-underline bg-btn-background hover:bg-btn-background-hover"
                        >
                            Login
                        </Link>
                    )}
                </div>
            </nav>
            <div className='flex justify-center items-center flex-grow bg-[#FAF3EA] w-screen'>
                <div className="flex flex-col h-full w-4/5 font-mono overflow-hidden">
                    {messages.length === 0 && <div className="flex p-20 text-black text-5xl font-bold text-center justify-center">{getGreeting()}</div>}
                    <div className="flex-1 p-4 overflow-y-auto">
                        {messages.map((msg, index) => (
                            <div className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`} key={index}>
                                <div className={`my-1 text-base px-4 py-2 rounded-3xl inline-block max-w-[75%] ${msg.type === 'user' ? 'bg-[#F5EADC] text-black' : 'bg-transparent text-black'}`}>
                                    <ReactMarkdown components={{ a: customLinkRenderer }}>
                                        {msg.content}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="max-h-40 px-5 relative z-15 w-full mx-auto max-w-1.5xl opacity-1">
                        <form ref={formRef} onSubmit={askQuestion} className="relative flex h-full w-full cursor-text items-end border border-transparent bg-gray-200 shadow-md transition-all rounded-3xl hover:border-gray-400 focus-within:border-gray-400 hover:shadow-none focus-within:shadow-none">
                            <div className="h-full w-full flex-grow overflow-y-auto pl-5 pr-4">
                                <input
                                    type="text"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                    placeholder="Ask a question"
                                    className="block resize-none whitespace-pre-wrap bg-transparent text-gray-800 outline-none border-none w-full pt-1.5 h-9 scrollbar-none placeholder-gray-600"
                                />
                            </div>
                            <button
                                type="button"
                                onClick={toggleListening}
                                className="flex h-9 w-9 items-center justify-center rounded-full p-1.5 text-gray-600 bg-gray-50 m-2 transition-colors duration-300"
                            >
                                {isListening ? <MicOff size={20} /> : <Mic size={20} />}
                            </button>
                            <button type="submit" className="flex h-9 w-9 items-center justify-center rounded-full p-1.5 text-gray-600 bg-gray-50 m-2 transition-colors duration-300" disabled={!question}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="currentColor">
                                    <path fillRule="evenodd" d="M.852 7.648a1.2 1.2 0 0 1 0-1.696l4.8-4.8a1.2 1.2 0 0 1 1.696 0l4.8 4.8a1.2 1.2 0 1 1-1.697 1.696L7.7 4.897V14a1.2 1.2 0 0 1-2.4 0V4.897L2.548 7.648a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </form>
                    </div>

                    <div className='flex p-5 text-center font-bold justify-center'>
                        MOSAIC
                    </div>
                </div>
                {pdf_floating_window && (
                    <PDFFloatingWindow
                        key={pdfPath} // Add a key prop
                        pdfPath={pdfPath}
                        highlights={pdfHighlights}
                        onClose={handleClosePdfFloatingWindow}
                        pageNumber={pageNumber}
                    />
                )}
                {floatingWindowContent.url && (
                    <MemoizedFloatingWindow
                        key={floatingWindowContent.url} // Add a key prop
                        url={floatingWindowContent.url}
                        title={floatingWindowContent.title}
                        onClose={handleCloseFloatingWindow}
                    />
                )}
            </div>
        </div>
    );
};

export default Chat;
