import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from "@supabase/auth-ui-shared";

const supabase = createClient(
    "https://jtmcxhywzhzmamsxkuco.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp0bWN4aHl3emh6bWFtc3hrdWNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTcyNjc1MjEsImV4cCI6MjAzMjg0MzUyMX0.Jz8JLULy5HHlGeM3zG53YS9kwHIsrGODDvtmIkfbqwQ"
);

const AuthComponent = ({ onAuthChange }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            onAuthChange(session?.user || null);
            if (session) {

                navigate('/chat');
            }
        });




        return () => subscription?.unsubscribe && subscription.unsubscribe();
    }, [onAuthChange, navigate]);


    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className='absolute text-center -mt-[70vh] text-6xl font-medium text-gray-800'>MOSAIC</div>

            <div className="bg-white rounded-lg shadow-md p-10 max-w-sm w-full text-center">
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    theme='minimal'
                    providers={[]}
                    socialColors={true}
                    authView='sign_in'
                />
            </div>
        </div>
    );
};

export default AuthComponent;
