import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-hls-quality-selector'; // Import the quality selector plugin

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, {
        ...options,
        userActions: {
            hotkeys: false // Disable keyboard shortcuts
        }
         }, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
        
        // Initialize the HLS quality selector plugin
        player.hlsQualitySelector({
          displayCurrentQuality: true,
        });

        player.el().onkeydown = (e) => {
          e.preventDefault();
          e.stopPropagation();
      };

        // Set quality to auto by default
        player.on('loadedmetadata', () => {
          const qualityLevels = player.qualityLevels();
          for (let i = 0; i < qualityLevels.length; i++) {
            qualityLevels[i].enabled = false; // Disable all quality levels
          }
          qualityLevels[qualityLevels.length - 1].enabled = true; // Enable auto quality (usually the last one)
        });
      }));

      // Add event listener for play/pause to sync with control bar
      player.on('play', () => {
        const playButton = videoRef.current.querySelector('.vjs-play-control');
        if (playButton) {
          playButton.classList.remove('vjs-paused');
          playButton.classList.add('vjs-playing');
        }
      });

      player.on('pause', () => {
        const playButton = videoRef.current.querySelector('.vjs-play-control');
        if (playButton) {
          playButton.classList.remove('vjs-playing');
          playButton.classList.add('vjs-paused');
        }
      });
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
