import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthComponent from './auth';
import Chat from './chat';
import ProtectedRoute from './protected_route';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
    "https://jtmcxhywzhzmamsxkuco.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp0bWN4aHl3emh6bWFtc3hrdWNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTcyNjc1MjEsImV4cCI6MjAzMjg0MzUyMX0.Jz8JLULy5HHlGeM3zG53YS9kwHIsrGODDvtmIkfbqwQ"
);

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                setUser(session.user);
            }
        };


        fetchUser();
    }, []);

    return (
        <Router>
            <Routes>        
                <Route path="/login" element={<AuthComponent onAuthChange={setUser} />} />
                <Route path="/chat" element={
                    <ProtectedRoute user={user}>
                        <Chat user={user} />
                    </ProtectedRoute>
                } />
                <Route path="/" element={<Navigate to="/chat" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
