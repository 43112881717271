import React, { useState, useEffect, useRef } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { highlightPlugin, RenderHighlightContentProps, RenderHighlightTargetProps } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';

const PDFFloatingWindow = ({ pdfPath, highlights, onClose, pageNumber }) => {
  const [isLoading, setIsLoading] = useState(true);
  const viewerRef = useRef(null);
  const searchPluginInstance = searchPlugin({
    keyword: highlights,
  });

  console.log(pageNumber);

  const highlightPluginInstance = highlightPlugin({
    renderHighlightContent: (props) => (
      <div
        style={{
          background: 'yellow',
          color: 'black',
          padding: '0.5rem',
          position: 'absolute',
          left: `${props.highlightAreas[0].left}%`,
          top: `${props.highlightAreas[0].top + props.highlightAreas[0].height}%`,
          zIndex: 1,
        }}
      >
        {props.selectedText}
      </div>
    ),
    renderHighlightTarget: (props) => (
      <div
        style={{
          background: 'yellow',
          opacity: 0.4,
          position: 'absolute',
          left: `${props.highlightAreas[0].left}%`,
          top: `${props.highlightAreas[0].top}%`,
          width: `${props.highlightAreas[0].width}%`,
          height: `${props.highlightAreas[0].height}%`,
        }}
      />
    ),
  });

  useEffect(() => {
    if (!isLoading && highlights.length > 0 && viewerRef.current) {
      const searchAndHighlight = async () => {
        for (const text of highlights) {
          const searchInstance = viewerRef.current.search(text);
          await searchInstance.promise;
        }
      };
      searchAndHighlight();
    }
  }, [isLoading, highlights]);

  const handleDocumentLoad = (e) => {
    setIsLoading(false);
    viewerRef.current = e.viewer;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 h-5/6 max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">PDF Viewer</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {isLoading && (
          <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-500">Loading PDF...</p>
          </div>
        )}
        <div className="flex-grow overflow-auto">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfPath}
              plugins={[searchPluginInstance, highlightPluginInstance]}
              onDocumentLoad={handleDocumentLoad}
              initialPage={pageNumber[0]}
            />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default PDFFloatingWindow;