import React, { useRef, useState, useEffect } from 'react';
import VideoJS from './video_js_component';
import ReactPlayer from 'react-player';

const FloatingWindow = ({ url, title, onClose }) => {
    const windowRef = useRef(null);
    const playerRef = useRef(null);
    const [isMinimized, setIsMinimized] = useState(true);
    const [startTime, setStartTime] = useState(0);
    const [isYouTube, setIsYouTube] = useState(false);
    const [shouldPlay, setShouldPlay] = useState(false);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: url.split('#')[0], // Remove the fragment identifier for video.js
        }],
        
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        if (startTime > 0) {
            player.currentTime(startTime);
        }
        if (startTime === 0){ 
            const startTime = getStartTimeFromUrl(url);
            setStartTime(startTime);
            player.currentTime(startTime);
        }

        if (shouldPlay) {
            player.play();
        }

        player.on('waiting', () => {
            console.log('player is waiting');
        });
        const videoElement = player.el();
        videoElement.addEventListener('keydown', (e) => e.preventDefault());

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const handleMouseDownResize = (e) => {
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = parseInt(document.defaultView.getComputedStyle(windowRef.current).width, 10);
        const startHeight = parseInt(document.defaultView.getComputedStyle(windowRef.current).height, 10);

        const doDrag = (e) => {
            const newWidth = startWidth + e.clientX - startX;
            const newHeight = startHeight + e.clientY - startY;
            windowRef.current.style.width = newWidth + 'px';
            windowRef.current.style.height = newHeight + 'px';
        };

        const stopDrag = () => {
            document.documentElement.removeEventListener('mousemove', doDrag, false);
            document.documentElement.removeEventListener('mouseup', stopDrag, false);
        };

        document.documentElement.addEventListener('mousemove', doDrag, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
    };

    const handleMouseDownMove = (e) => {
        const startX = e.clientX - windowRef.current.offsetLeft;
        const startY = e.clientY - windowRef.current.offsetTop;

        const doMove = (e) => {
            windowRef.current.style.left = (e.clientX - startX) + 'px';
            windowRef.current.style.top = (e.clientY - startY) + 'px';
        };

        const stopMove = () => {
            document.documentElement.removeEventListener('mousemove', doMove, false);
            document.documentElement.removeEventListener('mouseup', stopMove, false);
        };

        document.documentElement.addEventListener('mousemove', doMove, false);
        document.documentElement.addEventListener('mouseup', stopMove, false);
    };

    const handleMinimize = () => {
        if (!isMinimized) {
            setIsMinimized(true);
            windowRef.current.style.width = '30vw';
            windowRef.current.style.height = '30vh';
            windowRef.current.style.top = 'auto';
            windowRef.current.style.left = 'auto';
            windowRef.current.style.bottom = '10px';
            windowRef.current.style.right = '10px';
            windowRef.current.style.transform = 'none';
            document.body.classList.remove('blur-background');
        } else {
            setIsMinimized(false);
            windowRef.current.style.width = '50vw';
            windowRef.current.style.height = '50vh';
            windowRef.current.style.top = '50%';
            windowRef.current.style.left = '50%';
            windowRef.current.style.transform = 'translate(-50%, -50%)';
            document.body.classList.add('blur-background');
        }
    };

    const handleClose = () => {
        onClose();
    };

    const isYouTubeUrl = (url) => {
        return /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(url);
    };

    const getStartTimeFromUrl = (url) => {
        const urlObj = new URL(url);
        const startTime = urlObj.searchParams.get('t') || urlObj.hash.split('t=')[1];
        return startTime ? parseInt(startTime, 10) : 0;
    };

    useEffect(() => {
        const youtubeCheck = isYouTubeUrl(url);
        setIsYouTube(youtubeCheck);
        const startTime = getStartTimeFromUrl(url);
        setStartTime(startTime);
        setShouldPlay(true); // Set shouldPlay to true to start playback after seeking
    }, [url]);

    useEffect(() => {
        if (playerRef.current && startTime > 0) {
            if (isYouTube) {
                playerRef.current.seekTo(startTime);
                // playerRef.current.getInternalPlayer().play();
            } else {
                playerRef.current.currentTime(startTime);
                playerRef.current.play();
            }
        }
    }, [startTime, isYouTube]);

    return (
        <div className="fixed z-[1000] w-[30vw] h-[30vh] bottom-2 right-2 transform-none bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden" ref={windowRef}>
            <div className="bg-gray-200 p-2 cursor-move flex items-center" onMouseDown={handleMouseDownMove}>
                <div className="flex items-center">
                    <button className="w-4 h-4 rounded-full border-none mr-2 bg-red-500 flex items-center justify-center text-sm leading-none text-center text-gray-900" onClick={handleClose}>×</button>
                    <button className="w-4 h-4 rounded-full border-none mr-2 bg-yellow-500 flex items-center justify-center text-sm leading-none text-center text-gray-900" onClick={handleMinimize}>{isMinimized ? '+' : '−'}</button>
                </div>
                <div className="flex-1 text-center text-sm">{title}</div>
            </div>
            <div className="p-0 h-[calc(100%-40px)]" onKeyDown={(e) => e.preventDefault()} tabIndex="-1" >
                {isYouTube ? (
                    <ReactPlayer
                        key={url}
                        url={url}
                        width="100%"
                        height="100%"
                        playing
                        controls
                        ref={playerRef}
                    />
                ) : (
                    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                )}
            </div>
            <div className="absolute bottom-0 right-0 w-5 h-5 bg-gray-300 cursor-nwse-resize" onMouseDown={handleMouseDownResize}></div>
        </div>
    );
};

export default FloatingWindow;
